import React from "react"
import { Link } from "gatsby"
import { Layout, Head, Grid } from "../components/Layout"
import { PurpleSection } from "../components/Elements"
import Banner from "../components/Banner"

const NotFound = () => {
  return (
    <Layout>
      <Head title="Page Not Found" />
      <Banner imageName="contact_graded.jpg" title="Page Not Found" />
      <PurpleSection id="NotFound">
        <Grid>
          <div className="grid-content">
            <h1>Oops! The page you were looking for is not here.</h1>
            <Link to="/">
              <p>Back to the homepage.</p>
            </Link>
          </div>
        </Grid>
      </PurpleSection>
    </Layout>
  )
}

export default NotFound
